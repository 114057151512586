import React from "react"
import { Link } from "gatsby"
import '../styles/utilitary.css'
import '../styles/section1.css'
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import imgSection1 from "../images/imagem-section1.png" // Tell webpack this JS file uses this image
import imgSection3 from "../images/imagem-section3.png" // Tell webpack this JS file uses this image
import polegar from "../images/polegar.png" // Tell webpack this JS file uses this image

import n1 from "../images/n1.png" // Tell webpack this JS file uses this image
import n2 from "../images/n2.png" // Tell webpack this JS file uses this image
import grill from "../images/grill.png" // Tell webpack this JS file uses this image
import frigideira from "../images/frigideira.png" // Tell webpack this JS file uses this image

import ecoMobile from "../images/eco-elements-mobile.png" // Tell webpack this JS file uses this image

import eco from "../images/eco-elements.png" // Tell webpack this JS file uses this image
import ilustracaocanto from "../images/carne-ilustracao.png" // Tell webpack this JS file uses this image
import Helmet from "react-helmet"
import { withPrefix} from "gatsby"
import scrollTo from 'gatsby-plugin-smoothscroll';





const IndexPage = () => (
  <Layout>
      <Helmet>
      <script src="https://code.jquery.com/jquery-3.5.1.min.js"
  integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
  crossorigin="anonymous"> 
  </script>
        <script src={withPrefix('script.js')} type="text/javascript" />
    </Helmet>
    <SEO title="Home" />
    {/* <div id="loader-div">
       <ul className="loadingul">
          <li className="loadingli"></li>
       </ul>
    </div> */}
    <div id="section-1" className="soft-black-bg">
      <div className="scroll-indicator" data-sal="slide-up" data-sal-delay="400"  data-sal-duration="800" data-sal-easing="ease">
          <div className="scroll-word gold-color sneak-regular" >scroll 
          </div>
          <div className="stroke-scroll gold-color">
          </div>
      </div>
      <div className="wrapper-container">
          <div className="left-col">
            <div className="foto-mobile">
          <img data-sal="slide-up"  data-sal-duration="800" data-sal-easing="ease" src={imgSection1} alt="" />
          </div>

            <div  className="left-element" data-sal="slide-up" data-sal-delay="400" data-sal-duration="800" data-sal-easing="ease">
                <div className="number-1 texto-simples sneak-medium gold-color">
                  <img  src={n1} alt="" />
                </div>
                <div className="text-1 texto-simples">
                  Quando no século XVI os Espanhóis levaram o gado para a Argentina, os animais encontraram o seu paraíso.
                </div>
            </div>
          </div>
          <div className="center-col">
            <img data-sal="slide-up"  data-sal-duration="800" data-sal-easing="ease" src={imgSection1} alt="" />
          </div>
          <div className="right-col">
            <div className="right-element" data-sal="slide-up" data-sal-delay="400"  data-sal-duration="800" data-sal-easing="ease">
                <div className="number-2 texto-simples sneak-medium gold-color">
                  <img  src={n2} alt="" />
                </div>
                <div className="text-2 texto-simples">
                  A vasta extensão das planícies da Argentina, as condições climatéricas excepcionais, e a produção de cereais, explicam a Tradição de produção de Carne com Qualidade Extraordinária. 
                </div>
            </div>
          </div>
      </div>
    </div>
    <div id="section-2" className="soft-black-bg">
      <div className="sticker-left">
          <div className="sticker-eco absara">
            Ecofriendly
          </div>
      </div>
      <div className="wrapper-container">
          <div className="first-container">
            <div className="titulo golden-hills black" data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease">
                Embalagem mais Sustentável
            </div>
          </div>
          <div className="second-container">
            <img className="imagem-desktop"   src={eco}  data-sal="slide-up"  data-sal-duration="800" data-sal-easing="ease" alt="" />
            <img className="imagem-mobile"  src={ecoMobile}  data-sal="slide-up"  data-sal-duration="800" data-sal-easing="ease" alt="" />

          </div>
          <div className="third-container" data-sal="slide-up" data-sal-delay="400"  data-sal-duration="800" data-sal-easing="ease">
            <div className="sustentabilidade absara">
                Sustentabilidade
            </div>
            <div className="ponto-separador">
            </div>
            <div className="plastico absara">
                Menos plástico
            </div>
            <div className="ponto-separador">
            </div>
            <div className="cartao absara">
                Mais cartão
            </div>
            <div className="ponto-separador">
            </div>
            <div className="protecao absara">
                Alta proteção
            </div>
          </div>
      </div>
      <div className="four-container">
          <div className="ilustracao" data-sal="slide-up"   data-sal-duration="800" data-sal-easing="ease">
          </div>
      </div>
    </div>
    <div id="section-3" className="soft-black-bg">
      <div className="wrapper-container">
          <div className="left-col">
            <div data-sal="slide-up"  data-sal-duration="800" data-sal-easing="ease" className="titulo gold-color absara">
                Ponto<br></br> da Carne
            </div>
            <div className="text-element" data-sal="slide-up"  data-sal-duration="800" data-sal-easing="ease">
                <div className="n1">
                  <img  src={n1} alt="" />
                </div>
                <div className="text texto-simples">
                  O ponto da carne depende do gosto de cada pessoa. <br></br> Nesta imagem pode analisar os diferentes pontos da carne e escolher o seu preferido. 
                </div>
            </div>
          </div>
          <div className="center-col" data-sal="slide-up"  data-sal-duration="800" data-sal-easing="ease">
            <img src={imgSection3} alt="" />
          </div>
          <div className="right-col">
            <div className="imagem-top">
                <img src={polegar} alt="" />
            </div>
            <div className="text-element" data-sal="slide-up"  data-sal-duration="800" data-sal-easing="ease">
                <div className="n2">
                  <img  src={n2} alt="" />
                </div>
                <div className="text texto-simples">
                  Pode avaliar o ponto da carne tocando nesta e comparando com a firmeza do seu polegar.
                </div>
            </div>
            <div  data-sal="slide-up"  data-sal-duration="800" data-sal-easing="ease"  className="polegar-mobile" >
                <img  src={polegar} alt="" />
            </div>
          </div>
      </div>
    </div>
    <div id="section-4" className="soft-black-bg">
      {/* <div className="titulo-container">
          <div className ="sugestoes absara gold-color">
            As nossas sugestões
          </div>
      </div> */}
      <div className="wrapper-container">
          <div className="left-col">
            <div className="titulo-picanha">
              <div className="conteudo golden-hills black">
                Picanha
              </div>
            </div>

            {/* <div className="card-element">
                <div className="fotografia">
                </div>
                <div className="titulo golden-hills gold-color">
                  Picanha
                </div>
            </div> */}
            {/* <div className="text-element">
                <div className="n1">
                  <img  src={grill} alt="" />
                </div>
                <div className="text texto-simples">
                  <div className="titulo absara gold-color">
                      Na Grelha
                  </div>
                  Retire a Picanha da embalagem 20 min antes da confecção e deixe à temperatura ambiente.
                  Pré-aqueça bem a grelha e adicione os bifes de Picanha. Deixe que permaneça na grelha o tempo necessário até ganhar cor. Salpique com sal no fim. 
                </div>
                

            </div>
            <div className="text-element">
                <div className="n1 frigi-picanha">
                  <img  src={frigideira} alt="" />
                </div>
                <div className="text texto-simples">
                  <div className="titulo absara gold-color">
                      Na Frigideira
                  </div>
                  Caso pretenda cozinhar a carne na frigideira. Pré-aqueça a frigideira e adicione um fio de azeite. Coloque a carne e deixe-a dourar de cada um dos lados, durante 2 a 3 minutos. Repita o processo e adicione sal a gosto. Depois de cozinhada, deixe repousar para tornar a carne mais tenra e suculenta.
                </div>
                

            </div> */}
          </div>
          <div className="right-col">
            <div className="intro-picanha absara black">
            A Picanha, com origem nas vastas planícies da Argentina e América do Sul, é uma carne única 
e adequada por excelência, à grelha.
            </div>
          <div className="text-element">
                <div className="n1" data-sal="slide-up"  data-sal-duration="800" data-sal-easing="ease">
                  <img  src={grill} alt="" />
                </div>
                <div className="text texto-simples black" data-sal="slide-up"  data-sal-duration="800" data-sal-easing="ease">
                  <div className="titulo absara black">
                      Na Grelha
                  </div>
                  <div className="opacity">
                  Retire a Picanha da embalagem, 20 min antes da confecção e deixe à temperatura ambiente.
Pré-aqueça bem a grelha e adicione os bifes de Picanha. Deixe que permaneça na grelha, o tempo necessário, até ganhar cor. Salpique com sal no final. 
                  </div>
                </div>
                

            </div>
            <div className="text-element">
                <div className="n2 frigi-vazia" data-sal="slide-up"  data-sal-duration="800" data-sal-easing="ease">
                  <img  src={frigideira} alt="" />
                </div>
                <div className="text texto-simples black" data-sal="slide-up"  data-sal-duration="800" data-sal-easing="ease">
                  <div className="titulo absara black">
                      Na Frigideira
                  </div>
                  <div className="opacity">
                  Caso pretenda, cozinhar a carne na frigideira. Pré-aqueça a frigideira e adicione um fio de azeite. Coloque a carne e deixe-a dourar de cada um dos lados, durante 2 a 3 minutos. Repita o processo e adicione sal a gosto. Depois de cozinhada, deixe repousar, para tornar a carne mais tenra e suculenta.<br></br>  Está pronta!                </div>
                </div>
            </div>
            {/* <div className="card-element">
                <div className="fotografia">
                </div>
                <div className="titulo golden-hills gold-color">
                  Vazia
                </div>
            </div>
            <div className="text-element">
                <div className="n2">
                  <img  src={grill} alt="" />
                </div>
                <div className="text texto-simples">
                  <div className="titulo absara gold-color">
                      Na Grelha
                  </div>
                  Retire o bife da embalagem 20 min antes da confecção e deixe à temperatura ambiente.
                  Pré aqueça bem a grelha e coloque a Vazia, durante 2 a 3 minutos de cada lado. O tempo selecionado tem relação direta com o ponto da carne, que pretende, que esta atinja.
                  Retire da grelha e deixe repousar por 3 minutos, antes de fatiar para que se mantenha mais suculenta. Salpique com sal no final.              
                </div>
            </div>
            <div className="text-element">
                <div className="n2 frigi-vazia">
                  <img  src={frigideira} alt="" />
                </div>
                <div className="text texto-simples">
                  <div className="titulo absara gold-color">
                      Na Frigideira
                  </div>
                  Caso pretenda cozinhar a carne na frigideira. Pré aqueça a frigideira e adicione um fio de azeite. Coloque a carne e deixe dourar de cada um dos lados, durante 2 a 3 minutos. Repita o processo e adicione sal a gosto. No fim de cozinhar, deixe a carne repousar para a tornar mais tenra e suculenta.
                </div>
            </div> */}
            
          </div>
      </div>
      <div className="wrapper-container2">
      <div className="left-col-mobile">

<div className="titulo-picanha">
  <div className="conteudo golden-hills black">
    Vazia
  </div>
</div>
</div>
          <div className="right-col">
          <div className="intro-vazia absara black">
          A Vazia é um dos cortes mais valorizado por todo mundo. É uma combinação perfeita entre sabor, suculência e tenrura. Na Argentina é conhecida como Bife de Chorizo e Bife Angosto.            </div>
          <div className="text-element">
                <div className="n1" data-sal="slide-up"  data-sal-duration="800" data-sal-easing="ease">
                  <img  src={grill} alt="" />
                </div>
                <div className="text texto-simples black"data-sal="slide-up"  data-sal-duration="800" data-sal-easing="ease">
                  <div className="titulo absara black" >
                      Na Grelha
                  </div>
                  <div className="opacity">
                  Retire o bife da embalagem, 20 min antes da confecção e deixe à temperatura ambiente.
Pré aqueça bem a grelha e coloque a Vazia, durante 2 a 3 minutos, de cada lado. O tempo selecionado, tem relação direta com o ponto da carne, que pretende, que esta atinja.
Retire da grelha e deixe repousar por 3 minutos, antes de fatiar para que se mantenha mais suculenta. Salpique com sal no final.
                  </div>
                </div>
                

            </div>
            <div className="text-element">
                <div className="n2 frigi-vazia" data-sal="slide-up"  data-sal-duration="800" data-sal-easing="ease">
                  <img  src={frigideira} alt="" />
                </div>
                <div className="text texto-simples black" data-sal="slide-up"  data-sal-duration="800" data-sal-easing="ease" >
                  <div className="titulo absara black">
                      Na Frigideira
                  </div>
                  <div className="opacity">
                  Caso pretenda, cozinhar a carne na frigideira. Pré-aqueça a frigideira e adicione um fio de azeite. Coloque a carne e deixe dourar de cada um dos lados, durante 2 a 3 minutos. Repita o processo e adicione sal a gosto. No fim de cozinhar, deixe a carne repousar, para a tornar mais tenra e suculenta. <br></br>Delicie-se!
                  </div>
                </div>
            </div>
          </div>
          <div className="left-col-desktop">

            <div className="titulo-picanha">
              <div className="conteudo golden-hills black">
                Vazia
              </div>
            </div>
            </div>
            {/* <div className="card-element">
                <div className="fotografia">
                </div>
                <div className="titulo golden-hills gold-color">
                  Picanha
                </div>
            </div> */}
            {/* <div className="text-element">
                <div className="n1">
                  <img  src={grill} alt="" />
                </div>
                <div className="text texto-simples">
                  <div className="titulo absara gold-color">
                      Na Grelha
                  </div>
                  Retire a Picanha da embalagem 20 min antes da confecção e deixe à temperatura ambiente.
                  Pré-aqueça bem a grelha e adicione os bifes de Picanha. Deixe que permaneça na grelha o tempo necessário até ganhar cor. Salpique com sal no fim. 
                </div>
                

            </div>
            <div className="text-element">
                <div className="n1 frigi-picanha">
                  <img  src={frigideira} alt="" />
                </div>
                <div className="text texto-simples">
                  <div className="titulo absara gold-color">
                      Na Frigideira
                  </div>
                  Caso pretenda cozinhar a carne na frigideira. Pré-aqueça a frigideira e adicione um fio de azeite. Coloque a carne e deixe-a dourar de cada um dos lados, durante 2 a 3 minutos. Repita o processo e adicione sal a gosto. Depois de cozinhada, deixe repousar para tornar a carne mais tenra e suculenta.
                </div>
                

            </div> */}


       
            {/* <div className="card-element">
                <div className="fotografia">
                </div>
                <div className="titulo golden-hills gold-color">
                  Vazia
                </div>
            </div>
            <div className="text-element">
                <div className="n2">
                  <img  src={grill} alt="" />
                </div>
                <div className="text texto-simples">
                  <div className="titulo absara gold-color">
                      Na Grelha
                  </div>
                  Retire o bife da embalagem 20 min antes da confecção e deixe à temperatura ambiente.
                  Pré aqueça bem a grelha e coloque a Vazia, durante 2 a 3 minutos de cada lado. O tempo selecionado tem relação direta com o ponto da carne, que pretende, que esta atinja.
                  Retire da grelha e deixe repousar por 3 minutos, antes de fatiar para que se mantenha mais suculenta. Salpique com sal no final.              
                </div>
            </div>
            <div className="text-element">
                <div className="n2 frigi-vazia">
                  <img  src={frigideira} alt="" />
                </div>
                <div className="text texto-simples">
                  <div className="titulo absara gold-color">
                      Na Frigideira
                  </div>
                  Caso pretenda cozinhar a carne na frigideira. Pré aqueça a frigideira e adicione um fio de azeite. Coloque a carne e deixe dourar de cada um dos lados, durante 2 a 3 minutos. Repita o processo e adicione sal a gosto. No fim de cozinhar, deixe a carne repousar para a tornar mais tenra e suculenta.
                </div>
            </div> */}
            
          
      </div>
</div>



  </Layout>
)

export default IndexPage
